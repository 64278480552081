import React, {useEffect} from "react"
import {withTranslation} from "react-i18next";
import config from "../../config/main.config"
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import {loadTextsByNamespace} from "../../../server/helper/ssrHelper";
import EditTextComponent from "../../components/editText/EditTextComponent";
import EditImageComponent from "../../components/editImage/EditImageComponent";
import {MAX_MOBILE_WIDTH, UseWindowSize} from "../../helper/util";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import EditHTMLComponent from "../../components/editHTML/EditHTMLComponent";
import history from "../../helper/browserHistory";
import i18n from "../../i18n";
import {useLocation} from "react-router-dom";

const AmbulantPage = () => {
    let applicationWidth = UseWindowSize().width;
    let location = useLocation();

    useEffect(() => {
        setTimeout(_ => {
            if (location.search && typeof window !== "undefined" && typeof document !== "undefined") {
                let element = document.getElementById(location.search.substring(1, location.search.length));
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }
            }
        }, 20)
    }, [location.search]);
    const namespace = 'ambulant';

    return (
        <div className={'ui grid'}>
            <div style={{display: 'none'}}>
                <BreadCrumbs breadCrumbData={[{title: 'ambulant', url: '/ambulant'}]}/>
            </div>
            <div className={"top-image"} style={{
                backgroundImage: "url(" + config.BASE_URL_IMAGES + "Calendula_ambulant_1920.jpg)",
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: 'center',
                position: 'relative'
            }}>
                <img src={config.BASE_URL_IMAGES + "bows/Bogen6_Stiftung.svg"} alt={"bogen"} style={{position:'absolute', bottom:0, left: 0}}/>
            </div>
            <div className={'row centered'} style={{marginBottom: '200px'}} id={'ambulant-top'}>
                <div className={'fourteen wide column page-content-wrapper'}
                     style={applicationWidth >= MAX_MOBILE_WIDTH ? {
                         position: 'absolute',
                         bottom: 0,
                         padding: 0
                     } : {}}>
                    {applicationWidth >= MAX_MOBILE_WIDTH ?
                        <Card className={'card-position'} style={{float: 'left', minWidth: '50%'}}>
                            <Card.Header className={'card-header'}>
                                <h1 style={{paddingBottom: "45px"}}><EditTextComponent id={`${namespace}-card-1-header`}
                                                                                       namespace={namespace}/></h1>
                            </Card.Header>
                            <Card.Content style={{borderTop: '0', padding: '1rem 0 0 0'}}>
                                <p>
                                    <EditTextComponent id={`${namespace}-card-1-text`}
                                                       namespace={namespace}/>
                                </p>
                            </Card.Content>
                        </Card> :
                        [<h1><EditTextComponent id={`${namespace}-card-1-header`} namespace={namespace}/>
                        </h1>,
                            <p>
                                <EditTextComponent id={`${namespace}-card-1-text`}
                                                   namespace={namespace}/>
                            </p>]
                    }
                </div>
            </div>
            <div className={'row centered page-content-wrapper'}
                 style={applicationWidth <= MAX_MOBILE_WIDTH ? {
                     rowGap: '4rem',
                     paddingTop: '4rem'
                 } : {paddingBottom: '4rem'}}>
                <div className={'eight wide computer fourteen wide mobile column margin-top-bottom'}
                     id={'ambulant-team'}>
                    <h1><EditTextComponent id={`${namespace}-header-1`} namespace={namespace}/></h1>
                    <p><EditHTMLComponent id={`${namespace}-text-1`} namespace={namespace}/></p>
                </div>
                <div className={'eight wide column'}>
                    <EditImageComponent className={'image-container'} id={`${namespace}-image-1`}/>
                </div>
                <div className={'eight wide column'}>
                    <EditImageComponent className={'image-container'} id={`${namespace}-image-2`}/>
                </div>
                <div className={'eight wide computer fourteen wide mobile column margin-top-bottom'}>
                    <h1><EditTextComponent id={`${namespace}-header-2`} namespace={namespace}/></h1>
                    <p><EditHTMLComponent id={`${namespace}-text-2`} namespace={namespace}/></p>
                </div>
            </div>
            <div className={'ui grid'} style={{
                padding: 0,
                width: '102%',
                backgroundImage: "url(" + config.BASE_URL_IMAGES + "bows/ambulant_gelbe-Flaeche.svg)",
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#F2AB2B'
            }}>
                <img style={{padding:0}} alt={'bogen ambulant'} src={config.BASE_URL_IMAGES + "bows/Bogen2_Footer_Hospiz_Weiss.svg"}/>
                <div className={'row centered page-content-wrapper'} style={{paddingTop: '10rem'}}>
                    <div className={'eight wide computer fourteen wide mobile column padding-right-bow-section'} id={'ambulant-beratung'}>
                        <h1 className={'color-white'}>
                            <EditTextComponent id={`${namespace}-header-3`}
                                               namespace={namespace}/>
                        </h1>
                        <p className={'color-white'}>
                            <EditHTMLComponent id={`${namespace}-text-5`} namespace={namespace}/>
                        </p>
                        <button onClick={_=>{history.push('/'+i18n.language+'/contact#ambulant')}} className={'button-blue'}>TERMIN VEREINBAREN ></button>
                    </div>
                    <div className={'eight wide computer fourteen wide mobile column padding-left-bow-section'}>
                        <h1 className={'color-white'}><EditTextComponent
                            id={`${namespace}-header-4`}
                            namespace={namespace}/></h1>
                        <p className={'color-white'}>
                            <EditHTMLComponent id={`${namespace}-text-6`} namespace={namespace}/>
                        </p>
                        <button onClick={_=>history.push('/'+i18n.language+'/donate')} className={'button-blue'}   style={applicationWidth >= MAX_MOBILE_WIDTH ? {
                            position: 'absolute',
                            bottom: '0'
                        } : {}}>SPENDEN ></button>
                    </div>
                </div>
                <div style={{display: 'flex', width: '100%', position: 'relative', bottom: '-140px'}}>
                    <svg width='1000' height='8' style={{margin: 'auto'}}>
                        <line x1='0' y1='0' x2='100%' y2='0' stroke='white' strokeWidth='8'/>
                    </svg>
                </div>
            </div>
        </div>
    )
}

AmbulantPage.fetchData = () => {
    return loadTextsByNamespace(config.BASE_URL, 'ambulant').then((text) => {
        return text;
    })
}

export default withTranslation()(AmbulantPage);